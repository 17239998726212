<template>
  <b-row>
    <!-- date input -->
    <b-col :md="colWidth">
      <b-form-group
        :label="`${prefix} Date`"
        :label-for="`${safePrefix}_date`"
      >
        <b-form-datepicker
          :id="`${safePrefix}_date`"
          v-model="dateData.date_only"
          placeholder="Choose a date"
          today-button
          reset-button
          close-button
          :state="state"
          @input="saveFormattedDate"
        />
      </b-form-group>
    </b-col>

    <!-- time input -->
    <b-col :md="colWidth">
      <b-form-group
        :label="`${prefix} Time`"
        :label-for="`${safePrefix}_time`"
      >
        <b-form-timepicker
          :id="`${safePrefix}_time`"
          v-model="dateData.time_only"
          placeholder="Choose a time"
          reset-button
          locale="en"
          :state="state"
          @input="saveFormattedDate"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BFormTimepicker,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    prefix: {
      type: String,
      default: '',
    },
    view: {
      type: String,
      default: 'half',
    },
  },
  data() {
    return {
      // form data
      dateData: {
        date_only: null,
        time_only: null,
      },
    }
  },
  computed: {
    safePrefix() {
      return this.prefix.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    },
    formattedDate() {
      return `${this.dateData.date_only} ${this.dateData.time_only}`
    },
    colWidth() {
      return this.view === 'full' ? 12 : 6
    },
  },
  created() {
    // split datetime value
    if (this.value) {
      this.formatDateForForm(this.value)
    }
  },
  methods: {
    formatDateForForm(dateString) {
      const startDate = Date.parse(dateString)
      this.$set(this.dateData, 'date_only', moment(startDate).format('YYYY-MM-DD'))
      this.$set(this.dateData, 'time_only', moment(startDate).format('HH:mm:ss'))
    },
    saveFormattedDate() {
      // save changed date to form data
      this.$emit('input', this.formattedDate)
    },
  },
}
</script>
