<template>
  <b-row>
    <b-col md="6">
      <h5>
        Select Company
      </h5>
      <div
        v-if="listView"
        id="company-select-list"
      >
        <b-list-group>
          <b-list-group-item
            v-for="company in visibleCompanies"
            :key="company.id"
            :class="{ active: selectedCompany === company.id }"
            @click="setCompany(company.id)"
          >
            {{ company.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
      <div
        v-if="!listView"
        id="company-select-dropdown"
      >
        <b-form-select
          v-model="selectedCompany"
          :options="visibleCompanies"
          value-field="id"
          text-field="name"
          @change="setCompany(selectedCompany)"
        >
          <!-- <b-form-select-option :value="null">Select an option</b-form-select-option> -->
        </b-form-select>
      </div>
    </b-col>
    <b-col md="6">
      <h5>
        Select Client
      </h5>
      <div
        v-if="listView"
        id="client-select-list"
      >
        <b-list-group>
          <b-list-group-item
            v-for="client in visibleClients"
            :key="client.id"
            :class="{ active: selectedClient === client.id }"
            @click="setClient(client.id)"
          >
            {{ client.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
      <div
        v-if="!listView"
        id="client-select-dropdown"
      >
        <b-form-select
          v-model="selectedClient"
          :options="currentClients"
          value-field="id"
          text-field="name"
          @change="setClient(selectedClient)"
        >
          <!-- <b-form-select-option :value="null">Select an option</b-form-select-option> -->
        </b-form-select>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BListGroup,
  BListGroupItem,
  BFormSelect,
  // BFormSelectOption,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BFormSelect,
    // BFormSelectOption,
  },
  props: {
    listView: {
      type: Boolean,
      default: false,
    },
    loadDefault: {
      type: Boolean,
      default: false,
    },
    loadCompany: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      selectedClient: null,
      selectedCompany: null,
      currentClients: null,
    }
  },
  computed: {
    visibleCompanies() {
      return this.$store.getters['campaigns/getAllCompanies']
    },
  },
  created() {
    this.$store.dispatch('campaigns/fetchAllCompanies')
      .then(() => {
        // timeout delay to give time for getters
        if (this.loadDefault) {
          this.loadDefaultCompany()
        } else if (this.loadCompany) {
          this.loadSetCompany()
        }
      })
  },
  methods: {
    loadDefaultCompany() {
      if (this.visibleCompanies) {
        this.selectDefaultCompany()
        setTimeout(() => this.loadDefaultClient(), 100)
      } else {
        setTimeout(() => this.loadDefaultCompany(), 100)
      }
    },
    loadDefaultClient() {
      if (this.currentClients) {
        this.selectDefaultClient()
      } else {
        setTimeout(() => this.loadDefaultClient(), 100)
      }
    },
    loadSetCompany() {
      if (this.visibleCompanies) {
        this.setCompany(this.loadCompany)
      } else {
        setTimeout(() => this.loadSetCompany(), 100)
      }
    },
    setCompany(companyId) {
      this.selectedCompany = companyId
      this.$emit('setCompany', companyId)

      // get list of clients
      useJwt.getClientsAsRoot(companyId)
        .then(response => {
          this.currentClients = response.data.data
          setTimeout(() => this.loadDefaultClient(), 100)
        })
        .catch(error => {
          console.log(error)
        })
    },
    setClient(clientId) {
      this.selectedClient = clientId
      this.$emit('setClient', clientId)
    },
    selectDefaultCompany() {
      if (this.visibleCompanies && this.visibleCompanies[0]) {
        const defaultCompanyId = this.visibleCompanies[0].id // select first company
        this.setCompany(defaultCompanyId)
      }
    },
    selectDefaultClient() {
      if (this.currentClients && this.currentClients[0]) {
        const defaultClientId = this.currentClients[0].id // select first client
        this.setClient(defaultClientId)
      }
    },
  },
}
</script>
